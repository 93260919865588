/* home-header */

h1,
.h1 {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.1;
}

h2,
.h2 {
  font-size: 24px;
  font-weight: 600;
}

h3,
.h3 {
  font-size: 18px;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 16px;
}

.col {
  flex-grow: 1;
  flex-shrink: 1;
}

.col-1-2,
.col-1-2-mobile {
  width: 50%;
}

.col-1-3,
.col-1-3-mobile {
  width: 33.33%;
}

.col-2-3,
.col-2-3-mobile {
  width: 66.66%;
}

.col-1-4,
.col-1-4-mobile {
  width: 25%;
}

.col-3-4,
.col-3-4-mobile {
  width: 75%;
}

.col-1-5,
.col-1-5-mobile {
  width: 20%;
}

.col-2-5,
.col-2-5-mobile {
  width: 40%;
}

.full-width {
  width: 100%;
}

.btn-special {
  display: inline-flex;
  z-index: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 35px;
  border: 0;
  border-radius: 30px;
  text-align: center;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  outline-style: none;
  color: #fff;
  transition: background 0.2s ease;
  cursor: pointer;
}

.btn:hover,
.btn:focus {
  text-decoration: none;
  outline-style: none;
}

.form-ctrl .checkbox {
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  padding-left: 28px;
  color: #222;
  cursor: pointer;
  user-select: none;
}

.form-ctrl .checkbox input {
  position: absolute;
  left: -20000px;
}

.form-ctrl .checkbox input:checked~.checkmark:after {
  opacity: 1;
}

.form-ctrl .checkbox input:hover+.checkmark:before,
.form-ctrl .checkbox input:focus+.checkmark:before {
  border: 1px solid #222;
}

.form-ctrl .checkbox input:disabled~.checkmark {
  color: #222;
  cursor: not-allowed;
}

.form-ctrl .checkbox input:disabled~.checkmark:before {
  border: 2px solid #e7ebf0;
}

.form-ctrl .checkbox .checkmark {
  display: block;
  font-weight: 500;
}

.form-ctrl .checkbox .checkmark:before,
.form-ctrl .checkbox .checkmark:after {
  position: absolute;
  top: 50%;
  box-sizing: content-box;
}

.form-ctrl .checkbox .checkmark:before {
  content: "";
  display: block;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #c1ccd8;
  border-radius: 5px;
  transition: all 0.15s ease;
  transform: translateY(-50%);
}

.form-ctrl .checkbox .checkmark:after {
  content: "\E90D";
  display: flex;
  left: 0;
  align-items: center;
  justify-content: center;
  width: calc( 18px + 1px * 2);
  height: calc( 18px + 1px * 2);
  font-family: "icomoon";
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  opacity: 0;
  transition: all 0.15s ease;
  transform: translateY(-50%);
}

.form-ctrl .checkbox--rounded .checkmark:before {
  border-radius: 20px;
}

.form-ctrl .checkbox--large {
  padding-left: 34px;
}

.form-ctrl .checkbox--large .checkmark:before {
  width: 22px;
  height: 22px;
}

.form-ctrl .checkbox--large .checkmark:after {
  width: calc( 22px + 1px * 2);
  height: calc( 22px + 1px * 2);
}

.form-ctrl .checkbox.checkbox--pink input:checked~.checkmark:before {
  border: 1px solid #e90062;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
}

.follow-us {
  padding-bottom: 50px;
  padding-top: 50px;
  background: #e90062
}

.img-rounded {
  position: relative;
  width: 150px;
  height: 150px;
  max-width: 150px;
}

.img-rounded img {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-family: "object-fit";
  -o-object-fit: cover;
  object-fit: cover;
}

.img-rounded--small {
  width: 48px;
  height: 48px;
}


.radio-buttons label {
  position: relative;
  top: 0;
  left: 0;
  margin-right: 8px;
  padding: 0;
  user-select: none;
}

.radio-buttons label input {
  position: absolute;
  left: -20000px;
}

.radio-buttons label input:hover~.radio-button-checker,
.radio-buttons label input:focus~.radio-button-checker {
  box-shadow: 0 0 0 2px inset #a49bad;
}

.radio-buttons label .radio-button-checker {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 23px;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0 0 0 2px inset #eff1f4;
  transition: all 0.2s ease;
  cursor: pointer;
}

.radio-buttons label .radio-button-checker:focus:hover {
  box-shadow: 0 0 0 2px inset #222;
}

.radio-buttons label .radio-button-checker:hover {
  box-shadow: 0 0 0 2px inset #a49bad;
}

.radio-buttons.radio-buttons--pink input:checked~.radio-button-checker {
  color: #fff;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  box-shadow: none;
}

.form-ctrl .select2-container--default {
  width: 100%;
}

.upload-avatar,
.upload-avatar input {
  width: 100%;
  cursor: pointer;
}

.form-search {
  padding: 24px;
  font-size: 14px;
}

.form-search .searchType_day_budget .hint {
  top: 14px;
  font-size: 13px;
}

.form-search .form_object_budget_and_duration input {
  min-width: 0;
}

.form-search .step_form_texts {
  font-weight: bold;
  margin-bottom: 0px;
}

.form-search .radio-container {
  padding: 4px 0;
  background: none;
}

.form-search .objects_padded p {
  line-height: 18px;
}

.home-header--top {
  position: absolute;
  margin-top: -2000px;
}

.home-header .home-top {
  position: relative;
}

#home-top {
  background: #ecf0f1;
}

.home-header .container_home_top {
  z-index: 98;
  position: relative;
  margin: auto;
  padding: 100px 15px 0px;
  height: calc(100vh - 65px);
}

.home-header .slider_img {
  position: absolute;
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  background-position: center center;
  background-size: cover;
}

.home-header .headband__backgroundMask {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 190px;
}

.home-header .headband__backgroundMask svg {
  position: absolute;
  bottom: 0;
}

.home-header .home-map {
  height: 100%;
  margin-top: 0;
}

.home-header .leaflet-container {
  height: 100%;
}

.home-header .col_slogan_applis {
  display: flex;
  z-index: 10;
  position: relative;
  flex-direction: column;
  order: -1;
}

.home-header .big_slogan,
.home-header .medium_slogan {
  text-shadow: 0 4px 12px rgba(0, 0, 0, 1);
  font-weight: bold;
  color: #fff;
  font-family: 'Eventsia', sans-serif;
}

.home-header .big_slogan {
  margin-top: 8%;
  font-size: 55px;
  margin-bottom: 45px;
  text-align: center;
}

.home-header .medium_slogan {
  font-size: 24px;
  line-height: 30px;
}

.home-header .home__bgTestimony {
  position: relative;
  width: 290px;
  margin-top: 127px;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: hsla(0, 0%, 100%, 0.8);
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
}

.home-header .home__bgTestimonyId {
  display: flex;
}

.home-header .home__bgTestimonyAvatar {
  margin-right: 8px;
}

.home-header .home__bgTestimonyQuote {
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 19px;
  color: #222;
}

.home-header .home__bgTestimonyName {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

.home-header .home__bgTestimonyRating svg {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.home-header .count-block {
  z-index: 20;
  float: right;
  left: 0;
  margin: 16px;
  padding: 16px 24px;
  font-size: 16px;
}

.relative {
  position: relative;
}

.inline-block {
  display: inline-block;
}

.max-full-width {
  max-width: 100%;
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 600;
}

.full-height {
  height: 100%;
}

.mg-top-s {
  margin-top: 8px;
}

.mg-right-s {
  margin-right: 8px;
}

.mg-top-l {
  margin-top: 24px;
}

.mg-right-l {
  margin-right: 24px;
}

.form-search .step_form_validated_pink_bolded {
  color: #e90062;
}

#form_object_select_search_type {
  border: 1px solid #c1ccd8;
}

.home_form {
  max-width: 380px;
  width: 380px;
  float: right;
}

.col_form_search {
  margin-top: 6%;
}

.form-control {
  font-size: 13px;
}

::placeholder {
  color: #222;
  font-style: initial !important;
  font-weight: 500;
}

.form-control {
  color: #222;
}

/* home-sections */

.card_principal {
  text-align: center;
  margin: 20px 0px;
}

.card_img {
  width: 128px;
  height: 128px;
  padding: 25px;
  margin-bottom: 10px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card_principal_hide {
  display: none;
}

.card_img:hover {
  border: 3px solid #e90062;
}

.card_text {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #222;
}

ul {
  list-style: none;
}

.menuDivider {
  width: 1px;
  height: 10vh;
  margin-right: 40px;
  border-left: 1px solid #fff;
}

.regions {
  position: relative;
  padding: 40px 0;
  background: rgb(245, 245, 245);
}

#map path {
  cursor: pointer;
  fill: #fff;
  stroke: #e90062;
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-opacity: .6;
}

#map path:hover {
  transition: fill .5s;
  fill: rgba(250, 74, 111, 0.7);
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

a,
button,
div,
input,
select,
span,
textarea {
  text-decoration: none;
  outline: 0;
}

.flex-center {
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.cardItem {
  width: 175px;
  height: 175px;
  margin: 0 8px 16px 8px;
  padding: 20px 40px 40px 40px;
  border: 1px solid #f0f0f0;
  border-radius: 50%;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 30px 20px -30px rgba(0, 0, 0, .08);
}

.cardItem:hover {
  box-shadow: 0 40px 25px -25px rgba(0, 0, 0, .1);
}

.cardIcon {
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 10px auto;
}

.cardLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #616161;
}

.cardIcon img {
  position: relative;
  top: 50%;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  transform: translateY(-50%);
  image-rendering: -webkit-optimize-contrast;
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  position: relative;
  height: 110px;
  margin: auto;
  overflow: hidden;
  background: #fff;
}

.slider::after,
.slider::before {
  content: "";
  z-index: 2;
  position: absolute;
  width: 200px;
  height: 200px;
  background: linear-gradient(to right, #fff 0, rgba(255, 255, 255, 0) 100%);
}

.slider::after {
  top: 0;
  right: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.slider::before {
  top: 0;
  left: 0;
}

/* additioner les widths des logos */

.slider .slide-track {
  display: flex;
  width: 3140px;
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
}

.slider .slide {
  /*width: 250px;*/
  height: 80px;
  padding-top: 15Px;
  text-align: center;
}

a.region_link {
  color: #222;
  font-weight: 600;
  font-size: 15px;
}

a.region_link:hover {
  color: #e90062;
}

.map_region path {
  -webkit-transition: fill .3s;
  transition: fill .3s;
  cursor: pointer;
  fill: #fff;
  stroke: #e90062;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.map_region:hover path {
  fill: #e90062;
}

.footer-social-links-wrapper .title-line.white {
  height: 1px;
  background: #fff;
}

.title-line.white {
  background: #fff;
}

.title-line {
  height: 2px;
}

.footer-social-links-wrapper .block-title {
  margin-right: 30px;
}

.block-title.with-line-right {
  position: relative;
  margin: 0;
  padding-right: 200px;
  font-weight: 700;
}

.block-title {
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  color: #fff;
}

.social-links {
  display: -ms-flexbox;
  display: flex;
}

.align-self-center {
  align-self: center;
}

.img-icon {
  border-radius: 50%;
  box-shadow: 0 10px 50px #d9d9e7;
}

.img-icon:hover {
  box-shadow: 0 20px 50px #d9d9e7;
}

.home_container {
  z-index: 10;
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
}

.home_icon {
  width: 114px;
  height: 114px;
  border-radius: 50%;
  background: #fff;
}

.home_icon>div {
  width: 63px;
  height: 63px;
}

.home_icon>div img {
  max-width: 100%;
}

.home_title h1 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}

.domain_search {
  margin-top: 67px;
  padding: 10px;
}

.domain_search_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 45px;
  opacity: .33;
  background: rgba(2, 12, 34, .85);
}

.domain_search_form {
  display: block;
  position: relative;
  width: 100%;
}

.domain_search_input {
  width: 100%;
  height: 70px;
  padding-left: 58px;
  border: none;
  border-radius: 35px;
  font-size: 18px;
  font-weight: 500;
  outline: 0;
  color: #232323;
  background: #fff;
}

.mg-bottom-l {
  margin-bottom: 15px;
}

.domain_search_input::-webkit-input-placeholder {
  font-size: 18px;
  font-weight: 500;
  color: #6b6b6b;
}

.domain_search_input:-moz-placeholder {
  font-size: 18px;
  font-weight: 500;
  color: #6b6b6b;
}

.domain_search_input::-moz-placeholder {
  font-size: 18px;
  font-weight: 500;
  color: #6b6b6b;
}

.domain_search_input:-ms-input-placeholder {
  font-size: 18px;
  font-weight: 500;
  color: #6b6b6b;
}

.domain_search_input::input-placeholder {
  font-size: 18px;
  font-weight: 500;
  color: #6b6b6b;
}

.domain_search_dropdown {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 570px;
  height: 70px;
  padding-right: 193px;
  border-radius: 35px;
  background: #ece3fd;
  cursor: pointer;
}

.domain_search_selected {
  border: none;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  background: #ece3fd;
}

.domain_search_dropdown ul {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 10px;
  width: 200px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-bottom: 15px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  opacity: 0;
  background: #fff;
}

.domain_search_dropdown.active ul {
  visibility: visible;
  opacity: 1;
}

.domain_search_dropdown ul li {
  font-size: 18px;
  font-weight: 500;
  color: #6b6b6b;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
}

.domain_search_dropdown ul li:not(:last-of-type) {
  margin-bottom: 8px;
}

.domain_search_dropdown ul li:hover {
  color: #000;
}

.domain_search_button {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  width: 193px;
  height: 70px;
  border: none;
  border-radius: 35px;
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 700;
  outline: 0;
  color: #fff;
  background: rgba(233, 0, 98, 0.8);
  cursor: pointer;
}

.home_round {
  position: absolute;
  width: 100%;
  height: 40px;
  bottom: -1px;
  left: 0;
}

.home_round svg {
  position: absolute;
  bottom: 0;
}

.partners {
  padding: 30px 0px 0px 0px;
}

.form-control:focus {
  color: #000;
  font-weight: 500;
}

.pulse-button {
  box-shadow: 0 0 0 0 rgba(233, 0, 98, 0.9);
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.pulse-button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}

.pulse-button-white {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.pulse-button-white:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

.flashit {
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .1;
  }
  100% {
    opacity: 1;
  }
}

.axeptio_mount *,
.axeptio_mount *:before,
.axeptio_mount *:after {
  box-sizing: inherit;
}

.jFxuip {
  z-index: 1100;
  position: fixed;
  left: 0px;
  bottom: 40px;
  max-width: 500px;
}

.jFxuip .Widget__WidgetStyle-zhn46e-1 {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  transform-origin: left bottom;
  animation: 0s ease 0s 1 normal both running none;
}

.axeptio_mount *,
.axeptio_mount *:before,
.axeptio_mount *:after {
  box-sizing: inherit;
}

.axeptio_mount *,
.axeptio_mount ::before,
.axeptio_mount ::after {
  box-sizing: border-box;
}

.fnUWJf {
  position: relative;
  display: block;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 24px, rgba(0, 0, 0, 0.05) 0px 2px 6px;
  background: rgb(255, 255, 255);
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

.fnUWJf .Widget__WidgetWrapper-zhn46e-2 {
  max-height: calc(80vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}

.axeptio_mount *,
.axeptio_mount *:before,
.axeptio_mount *:after {
  box-sizing: inherit;
}

.axeptio_mount *,
.axeptio_mount ::before,
.axeptio_mount ::after {
  box-sizing: border-box;
}

.cBkCtD {
  z-index: 100;
  position: relative;
}

.axeptio_mount *,
.axeptio_mount *:before,
.axeptio_mount *:after {
  box-sizing: inherit;
}

.izogaM {
  z-index: 100;
  position: relative;
  color: rgb(46, 46, 46);
  padding: 20px 20px 0px;
  overflow: hidden;
}

.dhXKsX.dhXKsX.dhXKsX {
  position: relative;
  margin-bottom: 10px;
  padding-top: 4px;
}

.jXFPrG {
  z-index: 100;
  position: relative;
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

.ddbuEj {
  display: block;
  font-family: SofiaProLight, sans-serif;
  font-size: 24px;
  line-height: 1.25;
  font-weight: 700;
  text-align: left;
  color: rgb(0, 0, 0);
  margin: 0px 0px 8px;
}

.ddbuEj small {
  font-size: 70%;
  font-weight: 400;
}

.ddbuEj span,
.ddbuEj small {
  display: block;
}

.axeptio_mount span {
  font-size: inherit;
}

.ddbuEj span,
.ddbuEj small {
  display: block;
}

.axeptio_mount *,
.axeptio_mount *:before,
.axeptio_mount *:after {
  box-sizing: inherit;
}

.bAwQkR,
.bAwQkR p,
.bAwQkR li {
  font-family: Roboto, sans-serif;
  color: rgb(102, 102, 102);
  font-size: 13px;
}

.bAwQkR,
.bAwQkR p {
  margin: 0px 0px 10px;
}

.izogaM .Elements__PolicyLink-xuz5b0-1 {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 12px;
}

.EYanK a {
  color: rgb(136, 136, 136);
}

.fnUWJf a {
  background-color: transparent;
}

.axeptio_mount span {
  font-size: inherit;
}

.axeptio_mount *,
.axeptio_mount *:before,
.axeptio_mount *:after {
  box-sizing: inherit;
}

.axeptio_mount *,
.axeptio_mount ::before,
.axeptio_mount ::after {
  box-sizing: border-box;
}

.jSppW {
  z-index: 300;
  position: relative;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.jSppW a {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(148, 148, 148);
  white-space: nowrap;
  opacity: 0.7;
  font-weight: 400;
  font-size: 11px;
  padding: 4px 10px;
  text-decoration: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 8px 8px 0px 0px;
  transition: none 0s ease 0s;
}

.fnUWJf a {
  background-color: transparent;
}

.jSppW a span {
  font-weight: 400;
  margin-right: 4px;
  font-size: 11px;
}

.cCrIYW {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  height: 18px;
}

.jXFPrG .Illustration__Img {
  z-index: 100;
  position: relative;
  max-width: 140px;
  max-height: 70px;
  object-fit: contain;
}

.axeptio_mount *,
.axeptio_mount *:before,
.axeptio_mount *:after {
  box-sizing: inherit;
}

.fnUWJf .Widget__WidgetWrapper-zhn46e-2 {
  background: rgb(255, 255, 255);
}

.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU>.ButtonGroup__BtnStyle-sc-1usw1pe-0:last-of-type {
  border-bottom-right-radius: 8px;
}

.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU>.ButtonGroup__BtnStyle-sc-1usw1pe-0 {
  flex: 1 1 0%;
}

.bFAdIU.bFAdIU.bFAdIU.bFAdIU.bFAdIU.bFAdIU {
  user-select: none;
  text-transform: none;
  text-overflow: unset;
  box-shadow: none;
  position: relative;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: auto;
  height: auto;
  min-width: auto;
  min-height: auto;
  font-family: Roboto, sans-serif, sans-serif;
  font-size: 14px;
  line-height: 1.25;
  white-space: normal;
  letter-spacing: 0px;
  cursor: pointer;
  font-weight: 700;
  color: rgb(255, 198, 34);
  outline: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  text-decoration: none;
  background: none rgb(255, 255, 255);
  overflow: unset;
  margin: 0px;
  padding: 12px 8px;
  border-radius: 0px;
  transition: all 0.15s ease 0s;
}

.fnUWJf .ButtonGroup__BtnStyle-sc-1usw1pe-0:last-of-type {
  border-bottom-right-radius: 8px;
}

.fnUWJf .ButtonGroup__BtnStyle-sc-1usw1pe-0 {
  background: rgb(255, 255, 255);
}

.fnUWJf button,
.fnUWJf [type="button"],
.fnUWJf [type="reset"],
.fnUWJf [type="submit"] {
  -webkit-appearance: button;
}

.fnUWJf button,
.fnUWJf select {
  text-transform: none;
}

.fnUWJf button,
.fnUWJf input {
  overflow: visible;
}

.iGvsNE.iGvsNE.iGvsNE.iGvsNE.iGvsNE.iGvsNE {
  user-select: none;
  text-transform: none;
  text-overflow: unset;
  box-shadow: none;
  position: relative;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: auto;
  height: auto;
  min-width: auto;
  min-height: auto;
  font-family: Roboto, sans-serif, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
  white-space: normal;
  letter-spacing: 0px;
  color: rgb(122, 122, 122);
  cursor: pointer;
  outline: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  text-decoration: none;
  background: none rgb(255, 255, 255);
  overflow: unset;
  margin: 0px;
  padding: 12px 8px;
  border-radius: 0px;
  transition: all 0.15s ease 0s;
}

.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU>.ButtonGroup__BtnStyle-sc-1usw1pe-0:not(:last-child) {
  border-right: 1px solid rgb(237, 237, 237);
}

.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU>.ButtonGroup__BtnStyle-sc-1usw1pe-0:first-of-type {
  border-bottom-left-radius: 8px;
}

.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU>.ButtonGroup__BtnStyle-sc-1usw1pe-0 {
  flex: 1 1 0%;
}

.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU.cSyWXU {
  z-index: 200;
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
  border-top: 1px solid rgb(237, 237, 237);
}

.iWsWqM {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
}

.iWsWqM .Thumbprint__Img {
  position: absolute;
  left: auto;
  top: -70px;
  right: -60px;
  transform: rotate(180deg);
  max-width: none;
}

.ctzOuD img {
  border-style: none;
}

.bMuyLV .sc-cQFLBn {
  margin-top: 6px;
  font-size: 12px;
}

.sign-up-input::placeholder {
  color: white;
  font-weight: 500;
  opacity: 1;
}

.sign-up-input:-ms-input-placeholder {
  color: white;
  font-weight: 500;
}

.sign-up-input::-ms-input-placeholder {
  color: white;
  font-weight: 500;
}

.trendCarousel .swiper-slide .title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  text-transform: capitalize;
  font-style: normal;
  line-height: 1.29;
  color: #fff;
  padding: 24px 16px 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-image: linear-gradient(180deg, transparent, #000);
  margin-bottom: 0px;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s infinite linear;
  box-sizing: border-box;
  border: 4px solid #e90062;
  border-radius: 50%;
  border-top-color: transparent;
}

#slider_other {
  object-fit: cover; 
  background-size: cover; 
  width: 100%; 
  height: 100%; 
  transition: none 0s ease® 0s; 
  text-align: inherit; 
  line-height: 0px; 
  border-width: 0px; 
  margin: 0px; 
  padding: 0px; 
  letter-spacing: 0px; 
  font-weight: 300; 
  font-size: 14px; 
  display: block; 
  visibility: inherit; 
  opacity: 1;
}


._1jxweeom {
  height: 65px;
  border-radius: 100px;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: rgb(202 202 202 / 15%) 0px 0px 0px 6px;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(222, 222, 222);
  border-image: initial;
}

._1jkbosm7 {
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  min-width: 0px;
}


._4w946cw {
  -webkit-box-align: center;
  -ms-flex-align: center;
  position: relative;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  margin: -1px;
  min-width: 0px;
}

._gor68n {
  position: relative;
  z-index: 1;
  display: flex;
}

._fmav2so {
  font-weight: 800;
  letter-spacing: 0.04em;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 2px;
}

._1xq16jy {
  padding-left: 15px !important;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  background: none;
  font-size: 15px;
  line-height: 18px;
  color: #222222;
  font-weight: 600;
  text-overflow: ellipsis;
}


._n3o7pji::after {
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 32px;
  bottom: 0px;
  content: "";
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 0;
}

._n3o7pji {
  cursor: pointer;
  display: block;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 32px;
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  min-width: 0px;
  padding: 22px 20px 22px 30px;
  margin: 0px;
}
._krjbj {
  border: 0px;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

._krjbj {
  border: 0px;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}


._43mycv {
  -ms-grid-row-align: center;
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  border-right: 1px solid #DDDDDD;
  -webkit-flex: 0 0 0px;
  -ms-flex: 0 0 0px;
  flex: 0 0 0px;
  height: 32px;
}


._1yulsurh {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: -1px;
  min-width: 0px;
  position: relative;
  -webkit-flex: 0.95 0 auto;
  -ms-flex: 0.95 0 auto;
  flex: 0.95 0 auto;
}

._1omae7o6 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0px;
  color: inherit;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0px;
  outline: none;
  overflow: visible;
  /*padding: 1px;*/
  padding: 14px 0px;
  text-align: left;
  text-decoration: none;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  -webkit-flex: 1 0 0%;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  width: 0px;
  z-index: 1;
}

._seuyf {
  display: flex;
  padding: 17px 20px 17px 20px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

._1omae7o6::after {
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 32px;
  bottom: 0px;
  content: "";
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 0;
}
._wtz1co {
  font-size: 12px;
  line-height: 16px;
  font-weight: 800;
  letter-spacing: 0.04em;
  padding-bottom: 2px;
}
._uh2dzp {
  font-size: 14px;
  line-height: 18px;
  color: #717171;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
._w64aej {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: -6px;
  padding-right: 9px;
  position: relative;
  z-index: 5;
}

._1mzhry13 {
  appearance: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: inherit;
  user-select: auto;
  height: 48px;
  max-width: 48px;
  position: relative;
  vertical-align: middle;
  z-index: 0;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  margin: 0px;
  outline: none;
  overflow: hidden;
  padding: 0px;
  text-decoration: none;
  border-radius: 24px;
  transition: max-width 0.2s cubic-bezier(0.35, 0, 0.65, 1) 0s;
}


._tcp689 {
  display: block;
  position: relative;
  pointer-events: none;
}

._163rr5i {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  -webkit-mask-image: -webkit-radial-gradient(center, white, black);
  overflow: hidden;
  border-radius: 8px;
}

._m9v25n {
  display: block;
  width: 100%;
  height: 100%;
  min-width: 200px;
  background-size: 200% 200%;
  opacity: 0;
  background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
  transition: opacity 1.25s ease 0s;
}

._1hb5o3s {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  padding: 16px;
  position: relative;
  z-index: 1;
}

._1eh6notg {
  font-weight: 600;
  opacity: 0;
  padding-left: 8px;
  padding-right: 4px;
  transition: opacity 0.1s cubic-bezier(0.35, 0, 0.65, 1) 0s;
}

.home-header .header-slider {
  background: url('https://res.cloudinary.com/hz26cscpg/image/upload/bg-home_gwr82i.jpg');
  position: absolute;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 100%;
}


@media (min-width: 1500px) {
  .home-header .big_slogan {
    margin-top: 15%;
    margin-bottom: 70px;
    font-size: 67px;
  }
}

@media (min-width: 1440px) {
  .services {
    padding-right: 80px;
    padding-left: 80px;
  }

  .features {
    padding-right: 80px;
    padding-left: 80px;
  }

  .news {
    padding-right: 80px;
    padding-left: 80px;
  }
}

@media (min-width: 950px) {
  .filter-p1 ._1jkbosm7 {
    -webkit-flex: 1 0 0%;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .filter-p1 ._1eh6notg {
    opacity: 1;
    transition: none 0s ease 0s;
  }
  .filter-p1 ._1mzhry13 {
    max-width: 200px;
    transition: none 0s ease 0s;
  }
}

@media (max-width: 991px) {
  .home-header .big_slogan {
    font-size: 34px;
    margin-bottom: 40px;
  }

  #show_prestation {
    padding: 0px 30px;
  }
  .home-header .big_slogan {
    font-size: 35px;
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .separator-hide {
    display: none;
  }
  .filter-p1 ._1yulsurh {
    box-shadow: rgba(202, 202, 202, 0.15) 0px 0px 0px 6px;
    background: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(222, 222, 222);
    border-image: initial;
    border-radius: 30px;
  }
  .filter-p1 ._1omae7o6 {
    padding: 15px 20px 15px 30px;
    z-index: 0;
  }
  .filter-p1 ._43mycv {
    border: none;
  }
  .filter-p1 ._seuyf {
    padding: 0px;
  }
  .filter-p1 ._w64aej {
    z-index: 0;
  }
}

@media (min-width: 991px) {
  .filter-p1 ._1jxweeom {
    display: flex;
    position: relative;
    width: 100%;
  }
  .filter-p1 ._1c7nvmy {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    height: 100%;
    min-width: 0px;
    pointer-events: auto;
  }
}
